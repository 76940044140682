import React, { useRef } from 'react';
import { getDomains, postChunks, postRun, getDownloadFile } from '../Api';
import { useState, useEffect, useCallback } from 'react';
 
import { Flex, Box, Spacer , Input, Container,  HStack, Skeleton, useBreakpointValue,
    Tabs, TabList, TabPanels, Tab, TabPanel, Stack, Card, CardBody, CardHeader,
    Heading, Text, useToast, IconButton, Menu, MenuButton,
    Button, MenuList, MenuItem,
    VStack,
    CardFooter,
} from '@chakra-ui/react';
 
import { RepeatIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { IoIosSend, IoMdDownload } from 'react-icons/io';
 
import LoadingOverlay from '../components/LoadingOverlayComponent';
import { usePageColor } from '../contexts/ColorContext';
 
import {newTheme} from '../components/mdstyle'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";
 
const ChatbotPage = ({checkExpireTime}) => {
 
    const colors = usePageColor('chatbot');
 
    const toast = useToast();
    const inputRef = useRef(null);
 
    const [allDomainsData, setAllDomainsData] = useState({ result: [] });
    const [activedDomainIndex, setActivedDomainIndex] = useState(1);
 
    const [inputQuery, setInputQuery] = useState("");
    const [postRunTriggered, setPostRunTriggered] = useState(false);
 
    const [queryResponses, setQueryResponses] = useState('');
    const [chunkResponses, setChunkResponses] = useState({ results: [] });
    const [naverApiResponses, setNaverApiResponses] = useState([]);
    const [isHandlingEvent, setIsHandlingEvent] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
 
    const [isQueryResponseShowed, setIsQueryResponseShowed] = useState(false);
    const [isChunkResponseShowed, setIsChunkResponseShowed] = useState(false);
    const [isAIResponseLoading, setIsAIResponseLoading] = useState(false);

    
    const togglePostRunWrapper = () => {
        if (!isHandlingEvent) {
            setIsHandlingEvent(true);
            handlePostRun();
            setTimeout(() => setIsHandlingEvent(false), 500);
        }
    };
 
    useEffect(() => {
        (async () => {
            try {
                const data = await getDomains();
                if (data.result === "No Token Sent") {
                    setAllDomainsData({ result: [] });
                } else {
                    setAllDomainsData(data);
                    //setActivedDomainIndex(data.result[0]?.domain_id);
                    setInputQuery(data.result.find(domain => domain.domain_id === activedDomainIndex)?.default_prompt_text || "데이터 로딩 중...")
                    //위 문장 주석처리 할 경우 inputQuery가 뜨지 않고 placeholder에 뜨도록 해둠.
                }                
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '도메인 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        })();
    }, [toast, activedDomainIndex]);
 
    const handleGetChunks = useCallback(async (fileChunks) => {
 
        await checkExpireTime();
 
        if (fileChunks.file_ids && fileChunks.chunk_ids) {
            try {
                const data = await postChunks(fileChunks);
                console.log("data", data);

                setChunkResponses(data?.results);
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        } else {
            return;
        }  
 
    }, [toast]);
   
 
    const handlePostRun = useCallback(async () => {
 
        await checkExpireTime();
   
        if (inputQuery === "") {
            toast({
                title: 'Failed',
                description: '질문을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        } else {
            setIsFormLoading(true);
           
            setQueryResponses(''); // 기존 AI답변 초기화
            setChunkResponses([]); // 기존 AI답변 청크 초기화
   
            setIsQueryResponseShowed(false);
            setIsChunkResponseShowed(false);
            setIsAIResponseLoading(true);

            try {
                const stream = postRun(inputQuery);

                for await (let token of stream) {
 
                    if (token.query_response) {
                       
                        setIsQueryResponseShowed(true);
                        setIsChunkResponseShowed(true);
                        setIsFormLoading(false);
                        setIsAIResponseLoading(false);
                       
                        setQueryResponses(prev => prev + token.query_response)
 
                    }
 
                    if (token.file_ids && token.chunk_ids) {
                        let fileChunks = {
                            file_ids: token.file_ids,
                            chunk_ids: token.chunk_ids
                        }
                        handleGetChunks(fileChunks);
                       
                    }
                   
                    // [{title: '제목', link: '링크', pDate: '날짜'}, {}, {}]
 
                    if (token.title && token.link && token.pDate) {
                        let newResponses = [];
 
                        for (let i = 0; i < token.title.length; i++) {
                            let naverApiResponse = {
                                title: token.title[i],
                                link: token.link[i],
                                pDate: token.pDate[i]
                            };
                            newResponses.push(naverApiResponse);
                        }
 
                        setNaverApiResponses(newResponses);
                    }
 
 
                    if (token.last_answer === "Y") {
                        setQueryResponses(token.query_response);
                        break;
                    }
                }
                setPostRunTriggered(true);
   
            } catch {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
   
            } finally {
                setIsFormLoading(false);
                setIsQueryResponseShowed(true);
                setIsAIResponseLoading(false);
            }
        }
    }, [inputQuery, toast, handleGetChunks, setPostRunTriggered])
    useEffect(()=>{
        if (postRunTriggered) {
            if (queryResponses.length < 5) {
                console.log("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
                setQueryResponses("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
            }
            setPostRunTriggered(false);
        }
       
    }, [postRunTriggered, queryResponses, setPostRunTriggered])
 
    useEffect(() => {
        if (chunkResponses && Object.keys(chunkResponses).length > 0) {
            setIsChunkResponseShowed(true);
        }
    }, [chunkResponses]);
 
    useEffect(()=>{
        setIsQueryResponseShowed(false);
        setIsChunkResponseShowed(false);
    }, [activedDomainIndex])
 
    const handleGetFileDownload = useCallback(async (fileId) => {
        try {
            await getDownloadFile(fileId)
        } catch {
            toast({
                title: 'Failed',
                description: '파일 다운로드 실패',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, []);
 
    const overflowTabFixed = useBreakpointValue({ base: false, md: true })
    return(
        <Container bg={colors.ContainerMainBg} maxW='100%' minH='95vh' size='container.3xl'>
            <Container
                maxW = 'container.xl'
                bg={colors.ContainerMainBg}
                color={colors.ContainerPromptColor}
            >      
                    <Flex direction='column'>
                        <Container maxW='' position='relative' pb='10'>                            
                        <LoadingOverlay isLoading={isFormLoading} bgColor={colors.ContainerMainBg}/>
                            {overflowTabFixed && (<Tabs mt='10'>                            
                                <TabList>
                                {allDomainsData.result.map((domainData)=>(
                                    <Tab
                                    key = {domainData.domain_id}
                                    onClick={()=>{
                                        setActivedDomainIndex(domainData.domain_id)
                                        //setInputQuery(domainData.default_prompt_text)
                                        
                                         console.log(domainData)
                                        // console.log(domainData.domain_id)
                                        // console.log(domainData.default_prompt_text)
                                    }}
                                    >
                                        {domainData.domain_name}
                                    </Tab>
                                ))}
                                </TabList>
                            </Tabs>)}
                            {!overflowTabFixed && (
                                <Flex mt='7' >                                
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={colors.AssistantMessageBg} w="300" borderRadius='13px'>
                                            {allDomainsData.result[activedDomainIndex-1]?.domain_name || "데이터 로딩 중..."}
                                        </MenuButton>
                                        <MenuList>
                                            {allDomainsData.result.map((domainData)=>(
                                                <MenuItem
                                                    key = {domainData.domain_id}
                                                    onClick={()=>{
                                                        setActivedDomainIndex(domainData.domain_id)
                                                        //setInputQuery(domainData.default_prompt_text)
                                                    }}
                                                    >
                                                    {domainData.domain_name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                    <Spacer />
                                    <HStack>                                                                                            
                                    </HStack>                                    
                                </Flex>
                            )}            
                            <Flex direction='column' >                                                                                    
                                <HStack mt='8'>
                                <Input
                                    placeholder={`어떤 질문을 하고 싶으신가요? ex) ${
                                        allDomainsData.result
                                        ? allDomainsData.result.find((domain) => domain.domain_id === activedDomainIndex)
                                            ?.default_prompt_text || "데이터 로딩 중..."
                                        : "데이터 로딩 중..."
                                    }`}
                                    borderRadius='13px'
                                    borderWidth='2px'
                                    onKeyDown={(e) => {
                                        if(e.key === 'Enter')                    
                                        // handlePostRun();    
                                        togglePostRunWrapper();                
                                    }}
                                    ref={inputRef}
                                    value={inputQuery}
                                    onChange={(e) => {
                                        setInputQuery(e.target.value); 
                                    }}
                                    
                                />
                                <IconButton
                                    borderRadius='13px'
                                    onClick={() => {
                                        // handlePostRun();
                                        togglePostRunWrapper();
                                    }}
                                    icon={<IoIosSend />}
                                    colorScheme='blue'
                                />
                                <IconButton
                                    icon={<RepeatIcon />}
                                    onClick={()=>{
                                        setIsQueryResponseShowed(false);
                                        setIsChunkResponseShowed(false);
                                        setActivedDomainIndex(1);
                                        //setInputQuery(allDomainsData.result[0]?.default_prompt_text || "데이터 로딩 중...")
                                    }}
                                    borderRadius='13px'
                                />
                                </HStack>
                            </Flex>                            
                        </Container>
                        <Spacer/>            
                        <Container alignContent='center' maxW='' mt='10'>
                            <Box>
                                <Tabs colorScheme='green' >
                                    <TabList>
                                        <Tab>AI 답변</Tab>
                                    </TabList>
                                    <TabPanels mt ='4' borderRadius='13px'>
                                        {isAIResponseLoading && (
                                            <Stack pt='30px'>
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                            </Stack>
                                        )}  
                                        {isQueryResponseShowed && (
                                        <TabPanel>
                                            <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                            {queryResponses}
                                            </Markdown>                                                                            
                                        </TabPanel>
                                        )}
                                    </TabPanels>                            
                                </Tabs>                                
                            </Box>                            
                            {isChunkResponseShowed && (
                                <Stack spacing='4' mt='10'>
                                {chunkResponses.map((chunkResponse) => (
                                    <Card
                                    key={`${chunkResponse.file_ids}-${chunkResponse.chunk_ids}`}
                                    size='lg'
                                    boxShadow="lg" borderRadius="13px" backgroundColor="white"
                                    >
                                    <CardHeader>
                                        <Flex>
                                        <Heading size='md'>
                                            {chunkResponse.title}
                                        </Heading>
                                        <Spacer />
                                        <IconButton
                                            icon={<IoMdDownload/>}
                                            onClick={() => handleGetFileDownload(chunkResponse.file_ids)}
                                        />
                                        </Flex>
                                        <Text fontSize="sm" color="gray.500">
                                        Pages: {chunkResponse.page_num}
                                        </Text>
                                    </CardHeader>
                                    <CardBody>
                                        <VStack>
                                            <Text fontSize='xl'>내용</Text>
                                            <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                                {chunkResponse.Content}
                                            </Markdown>
                                        </VStack>
                                       
                                    </CardBody>
                                    <CardFooter>
                                        <VStack>    
                                        <Text fontSize='xl'>문서 요약</Text>
                                        <Text>
                                        {chunkResponse.summary}
                                        </Text>
                                        </VStack>
                                    </CardFooter>
                                </Card>
                                ))}
                            </Stack>
                            )}
                            {isChunkResponseShowed && naverApiResponses.length > 0 && (
                                <Stack spacing='4' mt='10'>
                                {naverApiResponses.map((naverApiResponse) => (
                                    <Card
                                    key={`${naverApiResponse.title}-${naverApiResponse.link}`}
                                    size='lg'
                                    >
                                    <CardHeader>
                                        <Heading size='md'>{naverApiResponse.title}</Heading>
                                    </CardHeader>
                                    <CardBody>
                                        <Text>
                                        {naverApiResponse.link}
                                        </Text>
                                        <Text>
                                        {naverApiResponse.pDate}
                                        </Text>
                                    </CardBody>
                                </Card>                            
                                ))}
                                </Stack>
                            )}                                                
                        </Container>
                        <Spacer/>
                    </Flex>                            
            </Container>                                        
           
        </Container>
    )
 
 
}
 
export default ChatbotPage;