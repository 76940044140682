import { 
    Container, ButtonGroup, Tabs, TabList, TabPanels, Tab, TabPanel, 
    Divider, Textarea, HStack, Flex, Spacer, IconButton, Input, Button,
    Box, Text, VStack, useToast, Switch, Card, CardHeader, CardBody, Center
} from "@chakra-ui/react";
import Cookies from 'js-cookie';
import { IoMdDownload, IoIosSend, IoMdSend } from 'react-icons/io';
import { useState, useRef, useCallback, useEffect } from 'react';
import { FaPaperclip, FaPlus } from "react-icons/fa6";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { DeleteIcon, ArrowBackIcon, RepeatIcon} from '@chakra-ui/icons';

import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";
import { documentSummaryMDTheme } from '../components/mdstyle';

import LoadingOverlay from "../components/LoadingOverlayComponent";
import { 
    postMultitaskChat, getMultitaskChatHistory, 
    getMultitaskThreadHistory, postMultitaskSummaryDocuments,
    postMultitaskDownloadReport, putMultitaskChatFeedback
} from "../Api";
import { usePageColor } from "../contexts/ColorContext";
import { scrollToBottom } from "../components/animation";

import { IoTimerOutline } from "react-icons/io5";

const PlaygroundPage = ({domainMenu}) => {
    
    const colors = usePageColor('summary');

    const [currentNodeIndex, setCurrentNodeIndex] = useState(domainMenu.menu.PlaygroundPage?.DocumentSummary === true? 0: 1);

    // Tab-specific state variables

    const [documentSummaryInput, setDocumentSummaryInput] = useState('');
    const [documentSummaryFile, setDocumentSummaryFile] = useState([{fileName: '', file: null, id: Date.now()}]);
    const [documentSummaryResponse, setDocumentSummaryResponse] = useState({});

    const [chatMessages, setChatMessages] = useState([]);
    const [chatInput, setChatInput] = useState('');

    const [thisFileDeleteIcons, setThisFileDeleteIcons] = useState([]);
    const [deletingFileIndices, setDeletingFileIndices] = useState([]);

    const [isFileInputAppearing, setIsFileInputAppearing] = useState(false);
    const [isDocumentSummaryTitleFixed, setIsDocumentSummaryTitleFixed] = useState(false);

    const [isQueryLoading, setIsQueryLoading] = useState(false);
    const [isButtonsDisappeared, setIsButtonsDisappeared] = useState(false);
    
    const [isChatHistoryFormAppearing, setIsChatHistoryFormAppearing] = useState(false);
    const [isNode0CurrentResponseBeing, setNode0CurrentResponseBeing] = useState(false);

    const [chatHistory, setChatHistory] = useState({ result: [] });
    const [thisChatThreadId, setThisChatThreadId] = useState('');

    const messagesEndRef = useRef(null);
    const fileInputRefs = useRef(null);

    const toast = useToast();
    const [documentGuideQuery, setDocumentGuideQuery] = useState("");
    
    const isThisFunctionUsable = (menu) => {

        // 0은 모두 안됨
        // 1은 DocumentSummary만
        // 2는 ChatMessages만
        // 3은 둘 다
        
        if (menu.DocumentSummary === false && menu.ChatMessages === false) return 0;
        else if (menu.DocumentSummary === true && menu.ChatMessages === false) return 1;
        else if (menu.DocumentSummary === false && menu.ChatMessages === true) return 2;
        else if (menu.DocumentSummary === true && menu.ChatMessages === true) return 3;
        else return 0;
    }


    // 0. DocumentSummary

    const newDocumentSummary = useCallback(() => {
        setDocumentSummaryInput('');
        setDocumentSummaryFile([{fileName: '', file: null, id: Date.now()}]);
        setDocumentSummaryResponse({});
        setNode0CurrentResponseBeing(false);
        fileInputRefs.current.value = null;
    }, [fileInputRefs]);

    const handleFileChange = useCallback((e) => {
        const files = Array.from(e.target.files);

        if (files.length > 5) {
            toast({
                title: 'Failed',
                description: '파일은 최대 5개까지 올릴 수 있습니다.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            e.target.value = '';
            return;
        }

        let fileData = [];

        // 파일을 하나만 넣어야 하는 경우면
        if (currentNodeIndex !== 0) {
            const file = e.target.files[0];
            if (file) {
                fileData = [{
                    fileName: file.name,
                    file: file,
                    id: Date.now()
                }];
            }
        } else {
            files.map((file, index) => {
                let fileOneData = {
                    fileName: file.name,
                    file: file,
                    id: `${Date.now()}-${index}`,
                }

                fileData.push(fileOneData);
            })
        }

        if (currentNodeIndex === 0) {
            setDocumentSummaryFile(fileData);
        }
                    
        setIsFileInputAppearing(true);
    }, [currentNodeIndex]);


    const handleDeleteButtonClick = useCallback((index) => {
        setDeletingFileIndices(prev => [...prev, index]);
        // setIsFileInputDeleting(true);
        setTimeout(() => {
            if (currentNodeIndex === 0) {
                setDocumentSummaryFile(prev => prev.filter((_, i) => i !== index));
            } 
            setDeletingFileIndices(prev => prev.filter(i => i !== index));
        }, 500);
    }, [currentNodeIndex, fileInputRefs]);


    const handleDocumentSummary = useCallback(async (userPrompt, documentGuideQuery, userFile) => {
        // 0 -> 둘다안됨
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 1) return;

        if (userPrompt === '' && !isDocumentSummaryTitleFixed) {
            toast({
                title: 'Failed',
                description: '제목은 필수입니다. 제목을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
        
        if (
            !userFile || 
            !Array.isArray(userFile) || 
            userFile.length === 0 || 
            userFile.some(fileItem => !fileItem.file) // Check if any file is null or empty
        ) {
            toast({
                title: 'Failed',
                description: '파일이 없거나, 필드를 다 작성하지 않았습니다. 확인해주세요.',
                status: 'error',
                isClosable: true,
            });
            setNode0CurrentResponseBeing(false);
            return;
        }

        try {
            setIsQueryLoading(true);
            setIsButtonsDisappeared(true);
            setNode0CurrentResponseBeing(true);
            setDocumentSummaryResponse({});
            toast({
                title: 'Processing',
                description: '해당 작업을 처리하는데 오랜 시간이 소요될 수 있습니다. 페이지를 이탈하면 결과가 손실될 수 있습니다.',
                status: 'loading',
                isClosable: true,
                position: 'top-right',
                duration: 2000,
            }); 

            await new Promise(resolve => setTimeout(resolve, 2000));

            const data = await postMultitaskSummaryDocuments(userPrompt, documentGuideQuery, userFile, isDocumentSummaryTitleFixed);
            
            if (data?.error) {
                toast({
                    title: 'Failed',
                    description: data.error,
                    status: 'error',
                    isClosable: true,
                });
                setNode0CurrentResponseBeing(false);
                return;
            }
            
            setDocumentSummaryResponse(data);
            return;
        } catch (error) {
            toast({
                title: 'Failed',
                description: error.message ? error.message : '오류가 일어났습니다. 시스템 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            setNode0CurrentResponseBeing(false);
            return;
        } finally {
            setIsQueryLoading(false);
            setIsButtonsDisappeared(false);
        }
    }, [currentNodeIndex, isDocumentSummaryTitleFixed, isNode0CurrentResponseBeing])


    const handlePostMultitaskDownloadReport = useCallback(async ()=>{

        // 0 -> 둘다안됨
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 1) return;

        if (!documentSummaryResponse.report_title || !documentSummaryResponse.markdown_content) {
            toast({
                title: 'Failed',
                description: '요약문서가 생성중이니 잠시만 기다려주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }

        try {
            const reportTitle = documentSummaryResponse.report_title;
            const markdownContent = documentSummaryResponse.markdown_content;

            await postMultitaskDownloadReport(reportTitle, markdownContent);
        } catch (error) {
            toast({
                title: 'Failed',
                description: error.message ? error.message : '오류가 일어났습니다. 시스템 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, [documentSummaryResponse])

    // 1. Chat

    const newChat = useCallback(() => {
        setIsChatHistoryFormAppearing(false);
        setThisChatThreadId('');
        setChatMessages([]);
        Cookies.remove('chat_thread');
    }, []);

    const handleChatSend = useCallback(async() => {

        // 0 -> 둘다안됨
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 2) return;

        if (chatInput.trim()) {
            setChatMessages(prev => [...prev, { type: 'user', content: chatInput }]);
            setChatInput('');
        }
        
        const stream = postMultitaskChat(chatInput, thisChatThreadId);
        let assistantResponse  = '';

        const updateChatMessages = (newContent, chatUUID) => {
            setChatMessages(prev => {
                const newMessages = [...prev];
                if (newMessages.length > 0 && newMessages[newMessages.length - 1].type === 'assistant') {
                    newMessages[newMessages.length - 1].content = newContent;
                } else {
                    newMessages.push({
                        type: 'assistant',
                        content: newContent,
                        chat_uuid: chatUUID
                    });
                }
                return newMessages;
            });
        };

        for await (let token of stream) {
            if (token.query_response && token.chat_uuid && token.last_answer == "N") {
                assistantResponse += token.query_response;
                let chatUUID = token.chat_uuid;
                updateChatMessages(assistantResponse, chatUUID);
            }
            if (token.chat_thread) {
                if (thisChatThreadId === '') {
                    setThisChatThreadId(token.chat_thread);
                }
            }
        }

    }, [chatInput, thisChatThreadId]);


    useEffect(() => {
        (async () => {
            try {
                // 0 -> 둘다안됨
                // 1 -> DocumentSummary만
                // 2 -> ChatMessages만
                // 3 -> 둘다됨
                const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

                if (usable !== 3 && usable !== 2) return;

                const data = await getMultitaskChatHistory();
                if (data.result === "No Token Sent") {
                    setChatHistory({ result: [] });
                } else {
                    setChatHistory(data);
                }                
            } catch (error) {
                setChatHistory({ result: [] });
                throw new Error(error);
            }
        })();
    }, []);

    useEffect(() => {
        if (isDocumentSummaryTitleFixed) {
            setDocumentSummaryInput('');
        }
    }, [isDocumentSummaryTitleFixed, documentSummaryInput]);


    const handleGetMultitaskChatHistory = useCallback(async()=>{
        try {

                // 0 -> 둘다안됨
                // 1 -> DocumentSummary만
                // 2 -> ChatMessages만
                // 3 -> 둘다됨
                const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

                if (usable !== 3 && usable !== 2) return;

            const data = await getMultitaskChatHistory();
            if (data.result === "No Token Sent") {
                setChatHistory({ result: [] });
            } else {
                setChatHistory(data);
            }                
        } catch (error) {
            setChatHistory({ result: [] });
            throw new Error(error);
        }
    }, []);


    const handleGetChatThread = useCallback(async(thread_id)=>{

        // 0 -> 둘다안됨
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 2) return;

        const data = await getMultitaskThreadHistory(thread_id);
        try{
            if (data.result.length === 0) {
                setChatMessages([]);
            } else {
                let chatHistoryTemp = [];

                data.result.forEach((each) => {
                    chatHistoryTemp.push({ type: 'user', content: each.question });
                    chatHistoryTemp.push({ type: 'assistant', content: each.answer, chat_uuid: each.chat_uuid });
                });

                setChatMessages(chatHistoryTemp);
            }
        } catch (error) {
            setChatMessages([]);
            throw new Error(error);
        }
        
    }, [thisChatThreadId]);


    const handlePutMultitaskChatFeedback = async(chatUUID, feedback) => {

        // 0 -> 둘다안됨    
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 2) return;

        const data = await putMultitaskChatFeedback(chatUUID, feedback);
        if (data.result === 1) {
            toast({
                title: 'Success',
                description: '피드백이 성공적으로 전송되었습니다.',
                status: 'success',
                isClosable: true,
                position: 'top'
            });
        } else {
            toast({
                title: 'Failed',
                description: '피드백 전송에 실패했습니다. 다시 시도해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }

    // Common
    const setInputValue = (value) => {
        switch(currentNodeIndex) {
            case 1: setChatInput(value); break;
            default: setDocumentSummaryInput(value); break;
        }
    };
    

    const getInputFileValue = () => {
        switch(currentNodeIndex) {
            case 1: return [{fileName: '', file: null, id: Date.now()}];;
            default: return documentSummaryFile;
        }
        };
    

    const modalFileInputButtonClick = () => {
        const currentRef = fileInputRefs?.current;
        if (currentRef) {
            currentRef.click();
        } else {
            console.error(`No ref found for index ${currentNodeIndex}`);
        }
    };

    const handleSendButtonClick = useCallback(async() => {
        let userPrompt = '';
        let userFile = null;

        switch(currentNodeIndex) {
            case 1:
                await handleChatSend();
                return;
            default:
                userPrompt = isDocumentSummaryTitleFixed? '' : documentSummaryInput;
                userFile = documentSummaryFile;
                await handleDocumentSummary(userPrompt, documentGuideQuery, userFile);
                return;
        }
    }, [isDocumentSummaryTitleFixed, currentNodeIndex, documentSummaryInput, documentGuideQuery, documentSummaryFile, toast, handleChatSend, handleDeleteButtonClick]);

    // Utils
    useEffect(() => {
        Cookies.remove('chat_thread');
    }, []);

    useEffect(() => {
        if (isFileInputAppearing) {
            const timer = setTimeout(() => {
                setIsFileInputAppearing(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [isFileInputAppearing]);

    useEffect(() => {
        const fileValues = documentSummaryFile
        setThisFileDeleteIcons(new Array(fileValues.length).fill(false));
    }, [currentNodeIndex, documentSummaryFile]);

    useEffect(()=>{
        scrollToBottom(messagesEndRef);
    }, [chatMessages])

    const handleChatHistoryFormDisappeared = useCallback(() => {
        setIsChatHistoryFormAppearing(false);
    }, []);

    const allowNodeIndexTwoThisFileInputLeftIcon = (index) => {
        setThisFileDeleteIcons(prev => prev.map((icon, i) => i === index ? true : icon));
    };
    
    const resetFileDeleteIcons = (index) => {
        setThisFileDeleteIcons(prev => prev.map((icon, i) => i === index ? false : icon));
    };


    const renderPlacholderForDocumentSummary = () =>{

        if (isDocumentSummaryTitleFixed) {
            return "자동 제목 생성이 될 예정입니다."   
        } else {
            return "제목을 입력해주세요."
        }
    }
    
    return (
        <Container
            maxW="100%" 
            minH="100vh"
            bg={colors.ContainerMainBg}
            py='4'
        >
            <Flex direction='column' justifyContent="center" alignItems="center">                                                           
            <HStack maxW='100%' alignItems="stretch">
                <Container
                    borderRadius="16px"
                    p='4'
                    minH='90vh'
                    bg={colors.ContainerCenterBg}
                    position="relative"
                    mr="20px"
                    minW='container.md'
                    maxW="container.md"
                    overflow='auto'
                >
                    <LoadingOverlay isLoading={isQueryLoading} bgColor={colors.ContainerCenterBg}/>                    
                        <Flex direction='column' minH='85vh'>                                                           
                            {!isButtonsDisappeared && (                                
                                <Flex>
                                    <ButtonGroup spacing={3}>
                                    {domainMenu.menu.PlaygroundPage?.DocumentSummary === true? (
                                        <Button  
                                            borderRadius='20px'
                                            border='2px solid transparent'
                                            _selected={{ bg: '#c84557', borderColor: '#c84557', color: 'white'}}
                                            transition='all 0.3s ease'
                                            mr='3'
                                            onClick={()=>{setCurrentNodeIndex(0)}}
                                        >
                                            요약 문서
                                        </Button >
                                    ) : <></>}
                                    {domainMenu.menu.PlaygroundPage?.ChatMessages === true? (
                                        <Button  
                                            borderRadius='20px'
                                            border='2px solid transparent'
                                            _selected={{ bg: '#c84557', borderColor: '#c84557', color: 'white'}}
                                            transition='all 0.3s ease'
                                            mr='3'
                                            onClick={()=>{setCurrentNodeIndex(1)}}
                                        >
                                            채팅
                                        </Button >
                                    ) : <></>}  
                                    </ButtonGroup>                                          
                                    <Spacer />                                
                                    {currentNodeIndex === 1 && (
                                        <HStack>
                                            <IconButton 
                                                borderRadius='13px'                                    
                                                icon={<IoTimerOutline />}
                                                borderColor="#c84557"
                                                borderWidth='2px'
                                                color="#c84557"
                                                height="40px"
                                                bg='transparent'
                                                _hover={{ bg: '#c84557', color: 'white' }}
                                                transition='all 0.5s ease'
                                                onClick={async ()=>{
                                                    setIsChatHistoryFormAppearing(true);
                                                    handleGetMultitaskChatHistory();
                                                }}
                                            />
                                            <IconButton 
                                                borderRadius='13px'                                    
                                                icon={<FaPlus />}
                                                borderColor="#c84557"
                                                borderWidth='2px'
                                                color="#c84557"
                                                height="40px"
                                                bg='transparent'
                                                _hover={{ bg: '#c84557', color: 'white' }}
                                                transition='all 0.5s ease'
                                                onClick={()=>{
                                                    newChat();
                                                }}
                                        />
                                        </HStack>
                                    )}  
                                </Flex>                                                                                                                                                 
                            )}
                            <Divider 
                                mt='4' 
                                borderWidth='1px'
                                opacity={isQueryLoading ? 0 : 1}
                                transition='all 1s ease'
                                mb='3'
                            />                            
                            {currentNodeIndex === 0 && (
                                <Box 
                                    ref={messagesEndRef}
                                    style={{
                                        animation: isQueryLoading ? 'fadeOutDown 1s ease' : 
                                                !isQueryLoading && !isButtonsDisappeared ? 'fadeInUp 1s ease' : 'none'
                                    }}
                                >                                
                                <VStack align="stretch" spacing={10} mb={4}>
                                <Card borderRadius='16'>
                                    <CardHeader>
                                    <HStack>
                                        <Switch 
                                            id='documentSummarySwitch'
                                            onChange={()=>{
                                                setIsDocumentSummaryTitleFixed(prev=>!prev)
                                            }}
                                        />
                                        <Text>
                                            {isDocumentSummaryTitleFixed? "자동 제목 활성화!":"활성화되면 HCX가 자동으로 제목을 생성해줍니다."}
                                        </Text>
                                    </HStack>
                                    </CardHeader>
                                    <CardBody>
                                    <Input 
                                        onChange={(e)=>{
                                            setDocumentSummaryInput(e.target.value)
                                        }}
                                        borderRadius='13'
                                        placeholder={renderPlacholderForDocumentSummary()}
                                        value={documentSummaryInput}
                                        readOnly={isDocumentSummaryTitleFixed? true: false}
                                        bg={isDocumentSummaryTitleFixed? colors.InputBoxBg:colors.ContainerCenterBg}
                                        onKeyDown={(e) => {
                                            if(e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSendButtonClick();
                                            }                     
                                                        
                                        }}
                                        mb='10px' 
                                    />
                                    <Input
                                            placeholder='문서 작성 가이드를 입력해주세요.'
                                            borderRadius='13px'
                                            height='50px'
                                            value={documentGuideQuery}
                                            onChange={(e) => setDocumentGuideQuery(e.target.value)}
                                            mb='10px' 
                                            >    
                                        </Input> 
                                    </CardBody>                                                                        
                                </Card>
                                <Card borderRadius='16'>
                                    <CardHeader>
                                        <HStack>
                                            <Text>파일 업로드</Text>
                                            <Spacer />
                                            <IconButton 
                                                icon={<FaPlus />}
                                                onClick={modalFileInputButtonClick}
                                                borderRadius='13'
                                            />
                                        </HStack>
                                    </CardHeader>
                                    <CardBody>
                                    {getInputFileValue().length > 0 && getInputFileValue()[0].file && (
                                    <VStack>                                            
                                        {getInputFileValue().map((fileValue, index) => (
                                            <Button
                                                key={fileValue.id}
                                                borderRadius='13px'
                                                p='2'
                                                minW='100px'
                                                leftIcon={thisFileDeleteIcons[index] ? <DeleteIcon /> : <FaPaperclip />}
                                                _hover={{
                                                bg: 'red.500',
                                                color: 'white',
                                                transform: 'translateX(-10px)'
                                                }}
                                                onMouseEnter={() => allowNodeIndexTwoThisFileInputLeftIcon(index)}
                                                onMouseLeave={() => resetFileDeleteIcons(index)}
                                                onClick={() => handleDeleteButtonClick(index)}
                                                transition="all 0.5s ease"                            
                                                opacity={deletingFileIndices.includes(index) ? 0 : 1}                            
                                                style={{
                                                    animation: isFileInputAppearing ? 'fadeInRight 0.5s ease' : 
                                                                deletingFileIndices.includes(index) ? 'fadeOutLeft 0.5s ease' : 'none'
                                                }}
                                                whiteSpace="nowrap"
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                                display="block"
                                                maxW='100%'
                                            >
                                                {`${fileValue.fileName}`}
                                            </Button>
                                        ))}
                                    </VStack>
                                    )}
                                    </CardBody>
                                </Card>
                                </VStack>
                                <Box>
                                    {!isNode0CurrentResponseBeing && (
                                    <Flex>
                                        <Spacer />
                                        <Button
                                            borderRadius='12'
                                            leftIcon={<IoMdSend/>}
                                            colorScheme='blue'
                                            onClick={handleSendButtonClick}
                                        >
                                            요약 문서 생성
                                        </Button>
                                    </Flex>
                                    )}
                                    {(isNode0CurrentResponseBeing && (
                                        <Flex>
                                            <Button
                                                borderRadius='12'
                                                leftIcon={<RepeatIcon/>}
                                                onClick={()=>{
                                                    newDocumentSummary();                                                        
                                                }}
                                            >
                                                초기화
                                            </Button>
                                            <Spacer />
                                            <Button
                                                borderRadius='12'
                                                leftIcon={<IoMdSend/>}
                                                colorScheme='blue'
                                                onClick={handleSendButtonClick}
                                            >
                                                요약 문서 재생성
                                            </Button>
                                        </Flex>
                                    ))}                                       
                                </Box>
                            </Box>
                            )}                                                       
                            {currentNodeIndex === 1 && (
                            <Box>
                                <VStack align="stretch" spacing={10} mb={4}>
                                    {chatMessages.map((message, index) => (
                                        <>
                                            <Box 
                                                key={index} 
                                                bg={message.type === 'user' ? 'blue.100' : 'green.100'} 
                                                p={2} 
                                                borderRadius="md"
                                            >
                                                <Text fontWeight="bold">{message.type === 'user' ? 'You:' : 'AI:'}</Text>
                                                <Text>{message.content}</Text>                                                
                                            </Box>
                                            {message.type === 'assistant' && (
                                            <HStack>
                                                <IconButton
                                                    onClick={() => handlePutMultitaskChatFeedback(message.chat_uuid, 'good')}
                                                    icon={<FaRegThumbsUp />}
                                                />
                                                <IconButton
                                                    onClick={() => handlePutMultitaskChatFeedback(message.chat_uuid, 'bad')}
                                                    icon={<FaRegThumbsDown />}
                                                />                                                  
                                            </HStack> 
                                            )}                                               
                                        </>                                            
                                    ))}
                                </VStack>
                            </Box>
                            )}                                                                                                
                        <Spacer />                    
                        {(!isButtonsDisappeared && currentNodeIndex !==0) && (
                            <>                                
                                <HStack 
                                    alignItems="flex-end" 
                                    mt='5'
                                    w="100%" 
                                    style={{
                                        animation: isQueryLoading ? 'fadeOutDown 1s ease' : 
                                                !isQueryLoading && !isButtonsDisappeared ? 'fadeInUp 1s ease' : 'none'
                                    }}
                                >   
                                    <Textarea 
                                        placeholder="채팅 메시지를 입력하세요"
                                        borderRadius="13px"
                                        borderWidth="2px"
                                        mr={2}
                                        flex={1}
                                        minH="40px"
                                        maxH="200px"
                                        overflow="hidden"
                                        // readOnly={isDocumentSummaryTitleFixed? true: false}
                                        // bg={isDocumentSummaryTitleFixed? colors.InputBoxBg:colors.ContainerCenterBg}
                                        resize="none"
                                        value={currentNodeIndex === 0 ? documentSummaryInput : chatInput}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        rows={1}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
                                                e.preventDefault();
                                                handleSendButtonClick();
                                            }                                
                                        }}
                                    />
                                    <IconButton
                                        borderRadius="13px"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSendButtonClick()}}
                                        icon={<IoIosSend />}
                                        borderColor="#c84557"
                                        borderWidth='2px'
                                        color="#c84557"
                                        height="40px"
                                        bg='transparent'
                                        _hover={{ bg: '#c84557', color: 'white' }}
                                    />                                                                                                 
                                </HStack>                           
                            </>                         
                        )}                            
                    </Flex>                                                
                </Container>
                
                {isChatHistoryFormAppearing && (
                    <Container
                    borderRadius="16px"
                    p='4'
                    minH='90vh'
                    bg={colors.ContainerCenterBg}
                    minW='container.md'
                    maxW="container.md"
                    mr="20px"
                    overflow='auto'
                    >
                        <Flex>
                            <Text>채팅 기록</Text>
                            <Spacer />
                            <IconButton 
                                borderRadius='13px'
                                icon={<ArrowBackIcon />}
                                borderColor="#c84557"
                                borderWidth='2px'
                                color="#c84557"
                                height="40px"
                                bg='transparent'
                                _hover={{ bg: '#c84557', color: 'white' }}
                                transition='all 0.5s ease'
                                onClick={handleChatHistoryFormDisappeared}
                            />
                        </Flex>
                        {chatHistory.result.length === 0 && (
                            <Text>채팅 기록이 없습니다.</Text>
                        )}
                        {chatHistory.result.map((chat, index) => (
                            <Box 
                                key={index} 
                                p={2}
                                my={2} 
                                bg={colors.ContainerMainBg} 
                                borderRadius="md"
                                onClick={() => {                                    
                                    setThisChatThreadId(chat.chat_thread);
                                    handleGetChatThread(chat.chat_thread);
                                }}
                            >
                                <Text fontWeight="bold">{chat.question}</Text>
                            </Box>
                        ))}
                    </Container>
                )}
                {isNode0CurrentResponseBeing && currentNodeIndex === 0 && (
                    <Container
                        borderRadius="16px"
                        p='4'
                        h='90vh'
                        bg={colors.ContainerCenterBg}
                        minW='container.xl'
                        overflow='auto'
                    >

                        <Card borderRadius='16'>
                            <CardBody>
                                <Flex>
                                    <Center 
                                        fontSize='2xl' 
                                        fontWeight='bold'
                                        maxW='70%'
                                        overflow='hidden'
                                        whiteSpace='nowrap'
                                        textOverflow='ellipsis'
                                        display="block"                                                                          
                                    >
                                        {documentSummaryResponse.report_title? documentSummaryResponse.report_title: '파일 제목이 들어갈 예정입니다.'}                    
                                    </Center>
                                    <Spacer />
                                    <HStack>
                                        <IconButton 
                                            icon={<IoMdDownload />}
                                            borderRadius='13px'
                                            bg={colors.NavigationBarBg}
                                            onClick={handlePostMultitaskDownloadReport}
                                        />
                                    </HStack>
                                </Flex>                                
                            </CardBody>
                        </Card>
                        <Box mt='4'>
                        <Markdown 
                            components={ChakraUIRenderer(documentSummaryMDTheme)} 
                            skipHtml
                            
                        >       
                                {documentSummaryResponse.markdown_content? documentSummaryResponse.markdown_content:'파일 내용이 들어갈 예정입니다.'}                                   
                        </Markdown>
                        </Box>                        
                    </Container>
                )}
            </HStack>
            </Flex>
                <Input
                    type="file"
                    ref={fileInputRefs}
                    hidden
                    accept=".pdf, .hwp, .txt, .csv"
                    onChange={handleFileChange}
                    multiple
                />
        </Container>
    )
}

export default PlaygroundPage;